export class Templates {
  static TemplateOne: any[] = [
    {
      data: '/assets/images/Real Estate 1.jpg',
      type: 'image',
      cols: 2,
      rows: 2,
      style: '',
      gridStyle: '',
    },
    {
      data: '/assets/images/Real Estate 4.jpg',
      cols: 2,
      rows: 3,
      type: 'image',
      style: '',
      gridStyle: '',
    },
    {
      data: '/assets/images/Real Estate 3.jpg',
      type: 'image',
      cols: 2,
      rows: 2,
      style: '',
      gridStyle: '',
    },

    {
      data: '/assets/images/Real Estate 2.jpg',
      type: 'image',
      cols: 2,
      rows: 2,
      style: '',
      gridStyle: '',
    },
    {
      data: '/assets/images/Real Estate 5.jpg',
      type: 'image',
      cols: 2,
      rows: 2,
      style: '',
      gridStyle: '',
    },
    {
      data: '/assets/images/Real Estate 8.jpg',
      type: 'image',
      cols: 2,
      rows: 3,
      style: '',
      gridStyle: '',
    },
    {
      data: '/assets/images/Real Estate 6.jpg',
      type: 'image',
      cols: 2,
      rows: 2,
      style: '',
      gridStyle: '',
    },
    {
      data: '/assets/images/W landscape.jpg',
      text: '<h2 class="ql-align-center"><span style="color: #0052A1;">HACCOURT</span></h2><p class="ql-align-center"><span style="color: rgb(102, 163, 224);">MAISON</span></p><p class="ql-align-center"><strong style="color: rgb(102, 163, 224);">350.000 €</strong></p><p class="ql-align-center"><span style="color: #0052A1;">Réf.: </span></p><p class="ql-align-center"><img src="assets/images/bed.png" width="24" height="24"><strong style="color: rgb(102, 163, 224);">&nbsp;&nbsp;2&nbsp;&nbsp;<img src="assets/images/bath.png" width="24" height="24">&nbsp;&nbsp;2&nbsp;&nbsp;<img src="assets/images/area2.png" width="24" height="24">&nbsp;&nbsp;1000m²</strong></p>',
      cols: 2,
      rows: 2,
      type: 'both',
      style: '',
      gridStyle: '',
    },
  ];

  static TemplateTwo: any[] = [
    {
      data: '/assets/images/Real Estate 1.jpg',
      type: 'image',
      cols: 1,
      rows: 1,
      style: '',
    },
    {
      data: '/assets/images/Real Estate 2.jpg',
      cols: 1,
      rows: 1,
      type: 'image',
      style: '',
    },
    {
      data: '/assets/images/Real Estate 5.jpg',
      type: 'image',
      cols: 1,
      rows: 1,
      //   style: 'margin-top: 50px',
    },
    {
      data: '/assets/images/W landscape.jpg',
      text: '<h1 class="ql-align-center"><span style="color: #0052A1;">HACCOURT</span></h1><p class="ql-align-center"><span style="color: rgb(102, 163, 224);">MAISON</span></p><p class="ql-align-center"><strong style="color: rgb(102, 163, 224);">350.000 €</strong></p><p class="ql-align-center"><span style="color: rgb(102, 163, 224);">2ch. - 1 sdb - jdin</span></p><p class="ql-align-center"><span style="color: #0052A1;">Réf.: </span></p><p class="ql-align-center"><img src="assets/images/bed.png" width="24" height="24"><strong style="color: rgb(102, 163, 224);">&nbsp;&nbsp;2&nbsp;&nbsp;<img src="assets/images/bath.png" width="24" height="24">&nbsp;&nbsp;2&nbsp;&nbsp;<img src="assets/images/area2.png" width="24" height="24">&nbsp;&nbsp;1000m²</strong></p>',
      cols: 1,
      rows: 1,
      type: 'both',
      style: '',
    },
  ];

  static TemplateThree: any[] = [
    {
      data: '/assets/images/Real Estate 1.jpg',
      type: 'image',
      cols: 2,
      rows: 2,
      style: '',
    },
    {
      data: '/assets/images/Real Estate 2.jpg',
      cols: 1,
      rows: 1,
      type: 'image',
      style: '',
    },
    {
      data: '/assets/images/Real Estate 3.jpg',
      type: 'image',
      cols: 1,
      rows: 1,
    },
    {
      data: '/assets/images/Real Estate 5.jpg',
      type: 'image',
      cols: 1,
      rows: 1,
    },
    {
      data: '/assets/images/Real Estate 6.jpg',
      type: 'image',
      cols: 1,
      rows: 1,
    },
    {
      data: '/assets/images/W landscape.jpg',
      text: '<h2 class="ql-align-center"><span style="color: #0052A1;">HACCOURT</span></h2><p class="ql-align-center"><span style="color: rgb(102, 163, 224);">MAISON</span></p><p class="ql-align-center"><strong style="color: rgb(102, 163, 224);">350.000 €</strong></p><p class="ql-align-center"><span style="color: rgb(102, 163, 224);">2ch. - 1 sdb - jdin</span></p><p class="ql-align-center"><span style="color: #0052A1;">Réf.: </span></p><p class="ql-align-center"><img src="assets/images/bed.png" width="24" height="24"><strong style="color: rgb(102, 163, 224);">&nbsp;&nbsp;2&nbsp;&nbsp;<img src="assets/images/bath.png" width="24" height="24">&nbsp;&nbsp;2&nbsp;&nbsp;<img src="assets/images/area2.png" width="24" height="24">&nbsp;&nbsp;1000m²</strong></p>',
      cols: 1,
      rows: 1,
      type: 'both',
      style: '',
    },
  ];

  static TemplateFour: any[] = [
    {
      data: '/assets/images/Real Estate 1.jpg',
      type: 'image',
      cols: 3,
      rows: 3,
      style: '',
    },
    {
      data: '/assets/images/Real Estate 2.jpg',
      cols: 1,
      rows: 1,
      type: 'image',
      style: '',
    },
    {
      data: '/assets/images/Real Estate 3.jpg',
      type: 'image',
      cols: 1,
      rows: 1,
    },
    {
      data: '/assets/images/Real Estate 5.jpg',
      type: 'image',
      cols: 1,
      rows: 1,
    },
    {
      data: '/assets/images/Real Estate 6.jpg',
      type: 'image',
      cols: 1,
      rows: 1,
    },
    {
      data: '/assets/images/Real Estate 7.jpg',
      type: 'image',
      cols: 1,
      rows: 1,
    },
    {
      data: '/assets/images/Real Estate 8.jpg',
      type: 'image',
      cols: 1,
      rows: 1,
    },
    {
      data: '/assets/images/W landscape.jpg',
      text: '<h2 class="ql-align-center"><span style="color: #0052A1;">HACCOURT</span></h2><p class="ql-align-center"><span style="color: rgb(102, 163, 224);">MAISON</span></p><p class="ql-align-center"><strong style="color: rgb(102, 163, 224);">350.000 €</strong></p><p class="ql-align-center"><span style="color: #0052A1;">Réf.: </span></p>',
      cols: 1,
      rows: 1,
      type: 'both',
      style: '',
    },
  ];

  static TemplateFive: any[] = [
    {
      data: '/assets/images/Real Estate 1.jpg',
      type: 'image',
      cols: 2,
      rows: 2,
      style: '',
      gridStyle: '',
    },
    {
      data: '/assets/images/Real Estate 4.jpg',
      cols: 2,
      rows: 3,
      type: 'image',
      style: '',
      gridStyle: '',
    },
    {
      data: '/assets/images/Real Estate 3.jpg',
      type: 'image',
      cols: 2,
      rows: 2,
      style: '',
      gridStyle: '',
    },

    {
      data: '/assets/images/Real Estate 2.jpg',
      type: 'image',
      cols: 2,
      rows: 2,
      style: '',
      gridStyle: '',
    },
    {
      data: '/assets/images/Real Estate 5.jpg',
      type: 'image',
      cols: 2,
      rows: 2,
      style: '',
      gridStyle: '',
    },
    {
      data: '/assets/images/Real Estate 8.jpg',
      type: 'image',
      cols: 2,
      rows: 3,
      style: '',
      gridStyle: '',
    },
    {
      data: '/assets/images/Real Estate 6.jpg',
      type: 'image',
      cols: 2,
      rows: 2,
      style: '',
      gridStyle: '',
    },
    {
      data: '/assets/images/W landscape Blue.jpg',
      text: '<h2 class="ql-align-center"><span style="color: #FFFFFF;">HACCOURT</span></h2><p class="ql-align-center"><span style="color: #FFFFFF;">MAISON</span></p><p class="ql-align-center"><strong style="color: #FFFFFF;">350.000 €</strong></p><p class="ql-align-center"><span style="color: #FFFFFF;">Réf.: </span></p><p class="ql-align-center"><img src="assets/images/bed.png" width="24" height="24"><strong style="color: #FFFFFF;">&nbsp;&nbsp;2&nbsp;&nbsp;<img src="assets/images/bath.png" width="24" height="24">&nbsp;&nbsp;2&nbsp;&nbsp;<img src="assets/images/area2.png" width="24" height="24">&nbsp;&nbsp;1000m²</strong></p>',
      cols: 2,
      rows: 2,
      type: 'both',
      style: '',
      gridStyle: '',
    },
  ];

  static TemplateSix: any[] = [
    {
      data: '/assets/images/Real Estate 1.jpg',
      type: 'image',
      cols: 1,
      rows: 1,
      style: '',
    },
    {
      data: '/assets/images/Real Estate 2.jpg',
      cols: 1,
      rows: 1,
      type: 'image',
      style: '',
    },
    {
      data: '/assets/images/Real Estate 5.jpg',
      type: 'image',
      cols: 1,
      rows: 1,
      //   style: 'margin-top: 50px',
    },
    {
      data: '/assets/images/W landscape Blue.jpg',
      text: '<h1 class="ql-align-center"><span style="color: #FFFFFF;">HACCOURT</span></h1><p class="ql-align-center"><span style="color: #FFFFFF;">MAISON</span></p><p class="ql-align-center"><strong style="color: #FFFFFF;">350.000 €</strong></p><p class="ql-align-center"><span style="color: #FFFFFF;">2ch. - 1 sdb - jdin</span></p><p class="ql-align-center"><span style="color: #FFFFFF;">Réf.: </span></p><p class="ql-align-center"><img src="assets/images/bed.png" width="24" height="24"><strong style="color: #FFFFFF;">&nbsp;&nbsp;2&nbsp;&nbsp;<img src="assets/images/bath.png" width="24" height="24">&nbsp;&nbsp;2&nbsp;&nbsp;<img src="assets/images/area2.png" width="24" height="24">&nbsp;&nbsp;1000m²</strong></p>',
      cols: 1,
      rows: 1,
      type: 'both',
      style: '',
    },
  ];

  static TemplateSeven: any[] = [
    {
      data: '/assets/images/Real Estate 1.jpg',
      type: 'image',
      cols: 2,
      rows: 2,
      style: '',
    },
    {
      data: '/assets/images/Real Estate 2.jpg',
      cols: 1,
      rows: 1,
      type: 'image',
      style: '',
    },
    {
      data: '/assets/images/Real Estate 3.jpg',
      type: 'image',
      cols: 1,
      rows: 1,
    },
    {
      data: '/assets/images/Real Estate 5.jpg',
      type: 'image',
      cols: 1,
      rows: 1,
    },
    {
      data: '/assets/images/Real Estate 6.jpg',
      type: 'image',
      cols: 1,
      rows: 1,
    },
    {
      data: '/assets/images/W landscape Blue.jpg',
      text: '<h2 class="ql-align-center"><span style="color: #FFFFFF;">HACCOURT</span></h2><p class="ql-align-center"><span style="color: #FFFFFF;">MAISON</span></p><p class="ql-align-center"><strong style="color: #FFFFFF;">350.000 €</strong></p><p class="ql-align-center"><span style="color: #FFFFFF;">2ch. - 1 sdb - jdin</span></p><p class="ql-align-center"><span style="color: #FFFFFF;">Réf.: </span></p><p class="ql-align-center"><img src="assets/images/bed.png" width="24" height="24"><strong style="color: #FFFFFF;">&nbsp;&nbsp;2&nbsp;&nbsp;<img src="assets/images/bath.png" width="24" height="24">&nbsp;&nbsp;2&nbsp;&nbsp;<img src="assets/images/area2.png" width="24" height="24">&nbsp;&nbsp;1000m²</strong></p>',
      cols: 1,
      rows: 1,
      type: 'both',
      style: '',
    },
  ];

  static TemplateEight: any[] = [
    {
      data: '/assets/images/Real Estate 1.jpg',
      type: 'image',
      cols: 3,
      rows: 3,
      style: '',
    },
    {
      data: '/assets/images/Real Estate 2.jpg',
      cols: 1,
      rows: 1,
      type: 'image',
      style: '',
    },
    {
      data: '/assets/images/Real Estate 3.jpg',
      type: 'image',
      cols: 1,
      rows: 1,
    },
    {
      data: '/assets/images/Real Estate 5.jpg',
      type: 'image',
      cols: 1,
      rows: 1,
    },
    {
      data: '/assets/images/Real Estate 6.jpg',
      type: 'image',
      cols: 1,
      rows: 1,
    },
    {
      data: '/assets/images/Real Estate 7.jpg',
      type: 'image',
      cols: 1,
      rows: 1,
    },
    {
      data: '/assets/images/Real Estate 8.jpg',
      type: 'image',
      cols: 1,
      rows: 1,
    },
    {
      data: '/assets/images/W landscape Blue.jpg',
      text: '<h2 class="ql-align-center"><span style="color: #FFFFFF;">HACCOURT</span></h2><p class="ql-align-center"><span style="color: #FFFFFF;">MAISON</span></p><p class="ql-align-center"><strong style="color: #FFFFFF;">350.000 €</strong></p><p class="ql-align-center"><span style="color: #FFFFFF;">Réf.: </span></p>',
      cols: 1,
      rows: 1,
      type: 'both',
      style: '',
    },
  ];
  // static TemplateNine: any[] = [
  //   {
  //     data: '/assets/images/temfourpicfive.jpg',
  //     cols: 1,
  //     rows: 1,
  //     type: 'image',
  //     style: '',
  //   },
  //   {
  //     data: '/assets/images/temfourpicone.jpg',
  //     cols: 1,
  //     rows: 1,
  //     type: 'image',
  //     style: '',
  //   },
  //   {
  //     data: '/assets/images/temfourpiceigth.jpg',
  //     cols: 1,
  //     rows: 1,
  //     type: 'both',
  //     text: '<p><strong style="color: rgb(255, 255, 255);">LIMMOBILIERE</strong></p><h1><span style="color: rgb(255, 255, 0);">W</span><span style="color: rgb(255, 255, 255);">OLFS</span></h1><p><br></p>',
  //     style: '',
  //   },
  //   {
  //     data: '/assets/images/temfourpictwo.jpg',
  //     cols: 1,
  //     rows: 1,
  //     type: 'image',
  //     style: '',
  //   },

  //   {
  //     data: '/assets/images/temfourpicthree.jpg',
  //     cols: 1,
  //     rows: 1,
  //     type: 'image',
  //     style: '',
  //   },

  //   {
  //     data: '<p><strong>395.000 €</strong></p><h1><span style="color: rgb(0, 102, 204);">VILLA</span></h1><h1><span style="color: rgb(0, 102, 204);">HACCOURT</span></h1><p><strong style="color: rgb(102, 163, 224);">3CH | 2SDB | 1500 M2</strong></p>',
  //     type: 'text',
  //     cols: 2,
  //     rows: 1,
  //     style: '',
  //   },
  //   {
  //     data: '/assets/images/temfourpicfour.jpg',
  //     cols: 1,
  //     rows: 1,
  //     type: 'image',
  //     style: '',
  //   },

  //   {
  //     data: '/assets/images/temfourpicsix.jpg',
  //     type: 'image',
  //     cols: 1,
  //     rows: 1,
  //     style: '',
  //   },
  //   {
  //     data: '/assets/images/temfourpicseven.jpg',
  //     cols: 1,
  //     rows: 1,
  //     type: 'image',
  //     style: '',
  //   },
  //   {
  //     data: '/assets/images/temfourpiceigth.jpg',
  //     cols: 1,
  //     rows: 1,
  //     type: 'image',
  //     style: '',
  //   },
  //   {
  //     data: '/assets/images/temfourpicnine.jpg',
  //     cols: 1,
  //     rows: 1,
  //     type: 'image',
  //     style: '',
  //   },
  // ];
}
